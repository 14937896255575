import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createMuiTheme({
	palette: {
		primary: { main: '#ed1c24' },
		secondary: { main: '#f8f8f8' },
		grey: {
			100: '#F8F8F8',
			200: '#e2e2e2',
			300: '#A8A8A8',
		},
		background: {
			default: '#fff',
		},
	},
	typography: {
		useNextVariants: true,
	},
	admin: {
		drawerWidth: 250,
	},
})

theme = responsiveFontSizes(theme)

export default theme
