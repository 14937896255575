export const SAMPLE_ACTION = 'SAMPLE_ACTION'
export const GET_SAMPLE_DATA_SUCCESSFUL = 'GET_SAMPLE_DATA_SUCCESSFUL'

export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'

export const GET_SUBSCRIBED_INSTRUMENTS = 'GET_SUBSCRIBED_INSTRUMENTS'
export const GET_SUBSCRIBED_INSTRUMENTS_SUCCESS =
	'GET_SUBSCRIBED_INSTRUMENTS_SUCCESS'

export const GET_BACKTEST_DATA = 'GET_BACKTEST_DATA'
export const GET_BACKTEST_DATA_SUCCESS = 'GET_BACKTEST_DATA_SUCCESS'

export const GET_PAIR_DATA = 'GET_PAIR_DATA'
export const GET_PAIR_DATA_SUCCESS = 'GET_PAIR_DATA_SUCCESS'
export const GET_PAIR_SYNTHETIC_PRICES = 'GET_PAIR_SYNTHETIC_PRICES'
export const GET_PAIR_FILLS = 'GET_PAIR_FILLS'
export const GET_PAIR_ACTIVE_UUID = 'GET_PAIR_ACTIVE_UUID'
export const GET_PAIR_CONFIG = 'GET_PAIR_CONFIG'
export const GET_PAIR_LIVE_LOGS = 'GET_PAIR_LIVE_LOGS'

export const ADD_CURRENT_TABLE_ROW = 'ADD_CURRENT_TABLE_ROW'
export const REMOVE_HIGHLIGHTED_TABLE_ROW = 'REMOVE_HIGHLIGHTED_TABLE_ROW'
export const ADD_UPDATED_TABLE_ROW = 'ADD_UPDATED_TABLE_ROW'
export const REMOVE_UPDATED_TABLE_ROW = 'REMOVE_UPDATED_TABLE_ROW'

export const GET_ORDERS = 'GET_ORDERS'
export const SHOW_ORDER_SNACKBAR = 'SHOW_ORDER_SNACKBAR'
export const HIDE_ORDER_SNACKBAR = 'HIDE_ORDER_SNACKBAR'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'

export const GET_LIVE_MARKET_PRICES = 'GET_LIVE_MARKET_PRICES'
