import { GET_NOTIFICATIONS_SUCCESS } from '../actions'

const NotificationReducer = (state = {}, action) => {
	let newState
	switch (action.type) {
		case GET_NOTIFICATIONS_SUCCESS:
			if (state.notification) {
				newState = {
					...state,
					notification: [action.data, ...state.notification],
				}
			} else {
				newState = {
					...state,
					notification: [action.data],
				}
			}
			break
		default:
			return state
	}
	return newState
}

export default NotificationReducer
