import api from '.'
import { filterFormatter } from '../../helpers'

export const createPairApi = (data) => {
	const config = {
		method: 'POST',
		url: '/pair',
		data,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const fetchPairsApi = (
	filter,
	full_info,
	page_number = 1,
	limit = 10,
	running_pairs = false,
	expired
) => {
	const config = {
		method: 'GET',
		url: `/pair?filter=${filterFormatter(
			filter
		)}&full_info=${full_info}&page_number=${page_number}&limit=${limit}&running_pairs=${running_pairs}&expired=${expired}`,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const updatePairApi = (data) => {
	const config = {
		method: 'PUT',
		url: `/pair/${data.pair_id}`,
		data,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const deletePairApi = (id) => {
	const config = {
		method: 'DELETE',
		url: `/pair/${id}`,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const manageConfigApi = (pair_id, action) => {
	const api_data = {
		pair_id,
		action,
	}
	const config = {
		method: 'POST',
		url: '/pair/manage',
		data: api_data,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const manageMultiConfigApi = (pair_ids, action, all_pairs) => {
	const api_data = {
		pair_ids,
		action,
		all_pairs,
	}
	!pair_ids && delete api_data.pair_ids
	// !all_pairs && delete api_data.all_pairs
	const config = {
		method: 'POST',
		url: '/pair/manage_multiple',
		data: api_data,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const fetchActiveUUID = () => {
	const config = {
		method: 'GET',
		url: '/pair/active_uuid',
	}

	return api(config).then((resp) => {
		return resp
	})
}
