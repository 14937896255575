import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import utility from './utility.Reducer'
import history from '../../routes/History'
import instruments from './instrumentReducer'
import backtest from './backtestReducer'
import pair from './pairReducer'
import order from './orderReducer'
import notification from './notificationReducers'

const rootReducer = combineReducers({
	router: connectRouter(history),
	utility,
	instruments,
	backtest,
	pair,
	order,
	notification,
})

export default rootReducer
