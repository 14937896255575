import React, { useState, useEffect, useContext, createContext } from 'react'
import md5 from 'md5'
import api from '../store/network/apis'
import { fetchTokenStatusApi } from '../store/network/apis/accountApis'
import { fetchAdminTokenStatusApi } from '../store/network/apis/adminUserApis'

const authContext = createContext()

// Provider hook that creates auth object and handles state

function useProvideAuth() {
	const [adminUser, setAdminUser] = useState(() => {
		if (localStorage.getItem('adminUser')) {
			const userAuth = JSON.parse(localStorage.getItem('adminUser'))
			return {
				isLoggedIn: true,
				authToken: userAuth.userToken,
				id: userAuth.id,
			}
		}
		return { isLoggedIn: false }
	})
	const [user, setUser] = useState(() => {
		if (localStorage.getItem('user')) {
			const userAuth = JSON.parse(localStorage.getItem('user'))
			api.defaults.headers.common.Authorization = userAuth.userToken
			return {
				isLoggedIn: true,
				authToken: userAuth.userToken,
			}
		}
		return { isLoggedIn: false }
	})

	// ... login.
	const login = (password, email) => {
		const payload = {
			email,
			password: md5(password),
		}
		return api({
			method: 'POST',
			url: '/user/login',
			data: payload,
		})
	}

	// ... register.
	const register = (payload) => {
		payload.password = md5(payload.password)
		return api({
			method: 'POST',
			url: '/account',
			data: payload,
		})
	}

	const updateUser = (token) => {
		api.defaults.headers.common.Authorization = token

		setUser((prev) => {
			return {
				...prev,
				isLoggedIn: false,
				authToken: token,
				// userID: userData.id,
			}
		})

		adminUser.isLoggedIn === false &&
			setAdminUser((prev) => {
				return {
					...prev,
					isLoggedIn: false,
					authToken: token,
				}
			})

		api({
			method: 'GET',
			url: '/account?auth=True',
		}).then((response) => {
			if (
				response.data.data.length &&
				response.data.data[0].account_type !== 'admin'
			) {
				setUser((prev) => {
					return {
						...prev,
						isLoggedIn: true,
						...response.data.data[0],
					}
				})
				localStorage.setItem(
					'user',
					JSON.stringify({
						userToken: token,
						// userID: userData.id,
					})
				)
				fetchTokenStatusApi()
					.then((resp) => {
						if (typeof resp.data.data === 'string') {
							window.location.replace(resp.data.data)
						}
					})
					.catch((err) => {
						console.log(err)
					})
			} else if (response.data.data[0].account_type === 'admin') {
				setAdminUser((prev) => {
					return {
						...prev,
						isLoggedIn: true,
						...response.data.data[0],
					}
				})
				localStorage.setItem(
					'adminUser',
					JSON.stringify({
						userToken: token,
						id: response.data.data[0].id,
					})
				)
				fetchAdminTokenStatusApi(undefined, { authToken: token })
					.then((resp) => {
						if (typeof resp.data.data === 'string') {
							window.location.replace(resp.data.data)
						}
					})
					.catch((err) => {
						console.log(err)
					})
			}
		})
	}

	const logout = () => {
		localStorage.removeItem('user')
		localStorage.removeItem('group')
		setUser({
			isLoggedIn: false,
			authToken: null,
		})
	}

	const logoutAdmin = () => {
		localStorage.removeItem('adminUser')
		localStorage.removeItem('group')
		setAdminUser({
			isLoggedIn: false,
			authToken: null,
		})
	}

	useEffect(() => {
		if (user.isLoggedIn) {
			api({
				method: 'GET',
				url: '/account?auth=True',
			})
				.then((response) => {
					if (response.data) {
						setUser(() => {
							return { ...user, ...response.data.data[0] }
						})
					} else {
						logout()
					}
					fetchTokenStatusApi()
						.then((resp) => {
							if (Object.keys(resp.data.data).length === 1) {
								return
							}
							const { pathname } = window.location
							if (
								typeof resp.data.data === 'string' &&
								pathname !== '/admin/zerodha-login/'
							) {
								window.location.replace(resp.data.data)
							}
						})
						.catch((err) => {
							console.log(err)
						})
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) logout()
				})
		}
	}, [])

	// Return the user object and auth methods
	return {
		user,
		adminUser,
		logoutAdmin,
		login,
		register,
		updateUser,
		logout,
	}
}
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext)
}
