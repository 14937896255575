import { call, put } from 'redux-saga/effects'
import * as actionTypes from '../actions'
import { fetchPairsApi } from '../network/apis/pairApis'

export const getPairDataSaga = function*(action) {
	try {
		const response = yield call(
			fetchPairsApi,
			action.filter,
			action.full_info
		)
		yield put({
			type: actionTypes.GET_PAIR_DATA_SUCCESS,
			data: response.status === 204 ? [] : response.data.data,
		})
	} catch (err) {
		console.log(err)
	}
}
