import React, { useEffect, useState } from 'react'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { Badge, Button, Container, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import useSound from 'use-sound'
import Message from './Message'
import { useWampy } from '../../contexts/Wampy'
import { useAuth } from '../../contexts/Auth'
import low_sound from './low.mp3'
import medium_sound from './medium.mp3'
import high_sound from './high.mp3'

const useStyles = makeStyles((theme) => ({
	bellIcon: {
		margin: theme.spacing(0, 1, 0, 0.2),
		display: 'flex',
		justifyContent: 'center',
		position: 'fixed',
		backgroundColor: '#ededed',
		zIndex: '3',
		'&:hover': {
			backgroundColor: '#d5d5d5',
		},
	},
	notification: {
		color: 'red',
	},

	dropdownContent: {
		position: 'fixed',
		right: '100px',
		top: '50px',
		zIndex: '2',
		display: 'flex',
		flexDirection: 'column',
		background: '#f4f4f4',
		width: '18rem',
		maxHeight: '60vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		padding: '0.1rem',
	},
}))

const Notifications = ({ notification }) => {
	const classes = useStyles()
	const [newNotifications, setNewNotifications] = useState(false)
	const [notes, setNotes] = useState(false)
	const [message, setMessage] = useState([])
	const [newMessage, setNewMessage] = useState(0)
	const wampy = useWampy()
	const auth = useAuth()
	const [play_low] = useSound(low_sound)
	const [play_medium] = useSound(medium_sound)
	const [play_high] = useSound(high_sound)

	useEffect(() => {
		auth.user.id && wampy.getNotifications(auth.user.id)
	}, [auth.user])

	useEffect(() => {
		if (notification) {
			setNewNotifications(true)
			setNewMessage(notification.length - (message ? message.length : 0))
		}
		setMessage(notification)
		if (
			notification &&
			notification.length > 0 &&
			!notification[0].soundless
		) {
			if (notification[0].level === 'high') {
				play_high()
			} else if (notification[0].level === 'medium') {
				play_medium()
			} else {
				play_low()
			}
		}
	}, [notification])

	return (
		<>
			<Button
				className={classes.bellIcon}
				onBlur={() => {
					setNotes(false)
					setNewNotifications(false)
				}}
				onClick={() => {
					if (newNotifications) setNewNotifications(false)
					if (notes) setNotes(false)
					else setNotes(true)
				}}
			>
				{newNotifications ? (
					<Badge badgeContent={newMessage} color="primary">
						<NotificationsActiveIcon
							className={classes.notification}
						/>
					</Badge>
				) : (
					<NotificationsNoneOutlinedIcon />
				)}
			</Button>
			{notes ? (
				<Container className={classes.dropdownContent}>
					{message ? (
						message.map((item) => (
							<Message key={item} message={item} />
						))
					) : (
						<Message message={{ data: 'No new notification' }} />
					)}
				</Container>
			) : null}
		</>
	)
}

const mapStateToProps = (state) => ({
	notification: state.notification.notification,
})

export default connect(mapStateToProps, null)(Notifications)
