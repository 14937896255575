import { call, put } from 'redux-saga/effects'
import * as actionTypes from '../actions'
import { fetchSubscribedInstruments } from '../network/apis/instrumentApis'

export const getSubscribedInstrumentSaga = function*(action) {
	try {
		const response = yield call(fetchSubscribedInstruments, action.filter)
		yield put({
			type: actionTypes.GET_SUBSCRIBED_INSTRUMENTS_SUCCESS,
			data: response.status === 204 ? [] : response.data.data,
		})
	} catch (err) {
		console.log(err)
	}
}
