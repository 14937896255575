import React, { useContext, createContext, useState } from 'react'

const utilsContext = createContext()

// Provider component that wraps your app and makes utils object ...
export function ProvideUtils({ children }) {
	const utils = useProvideUtils()
	return (
		<utilsContext.Provider value={utils}>{children}</utilsContext.Provider>
	)
}

// Hook for child components to get the utils object ...
// ... and re-render when it changes.
export const useUtils = () => {
	return useContext(utilsContext)
}

// Provider hook that creates utils object and handles state
function useProvideUtils() {
	const regex = {
		mobile: '^[0-9]{10,}$',
		email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$',
	}

	const checkFloat = (value) => {
		return value === '' ? value : parseFloat(value)
	}

	const checkPositiveInteger = (value) => {
		if (value === '') {
			return value
		}
		const val = parseInt(value)
		if (val < 0 || val === NaN || isNaN(val)) {
			return ''
		}
		return val
	}

	const goToLogsHandler = (type, id, history) => {
		history.push(`/admin/logs/${type}/${id}`)
	}

	// Subscribe to user on mount
	// Because this sets state in the callback it will cause any ...
	// ... component that utilizes this hook to re-render with the ...
	// ... latest utils object.
	// useEffect(() => {}, []);

	// Return the utils object and methods
	return {
		regex,
		goToLogsHandler,
		checkFloat,
		checkPositiveInteger,
	}
}
