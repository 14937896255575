import React from 'react'

export const Home = React.lazy(() =>
	import('../components/templates/HomepageTemplate')
)
export const AuthPage = React.lazy(() =>
	import('../components/templates/AuthTemplate')
)
export const SubscriptionPage = React.lazy(() =>
	import('../components/templates/SubscriptionTemplate')
)

export const InstrumentsPage = React.lazy(() =>
	import('../components/templates/InstrumentTemplate')
)
export const HoldingsPage = React.lazy(() =>
	import('../components/templates/HoldingsTemplate')
)
export const PostionsPage = React.lazy(() =>
	import('../components/templates/PositionTemplate')
)
export const LegsPage = React.lazy(() =>
	import('../components/templates/LegsTemplate')
)
export const GroupsPage = React.lazy(() =>
	import('../components/templates/GroupsTemplate')
)
export const PriceAdjusterPage = React.lazy(() =>
	import('../components/templates/PriceAdjusterTemplate')
)
export const PairsPage = React.lazy(() =>
	import('../components/templates/PairsTemplate')
)

export const LogsPage = React.lazy(() =>
	import('../components/templates/LogsTemplate')
)
export const MarketDepthPage = React.lazy(() =>
	import('../components/templates/MarketDepthTemplate')
)
export const BackorderPage = React.lazy(() =>
	import('../components/templates/BackorderTemplate')
)
export const AuditLogsPage = React.lazy(() =>
	import('../components/templates/AuditLogsTemplate')
)
export const TriggerLogsPage = React.lazy(() =>
	import('../components/templates/TriggerLogsTemplate')
)
export const MarketDepthChart = React.lazy(() =>
	import('../components/templates/MarketDepthChartTemplate')
)
export const OrdersPage = React.lazy(() =>
	import('../components/templates/OrderTemplate')
)
export const AdminUserListPage = React.lazy(() =>
	import('../components/Admin/AdminPage')
)
export const ZerodhaLoginPage = React.lazy(() =>
	import('../components/templates/ZerodhaLoginTemplate')
)
export const IntrumentsLiveData = React.lazy(() =>
	import('../components/templates/InstrumentsLiveDataTemplate')
)
export const TechnicalIndicator = React.lazy(() =>
	import('../components/templates/TechnicalIndicatorTemplate')
)
export const LegsOHLCChart = React.lazy(() =>
	import('../components/templates/LegsOHLCChartTemplate')
)
