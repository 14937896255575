import api from './'
import { InstrumentFilterFormatter, filterFormatter } from '../../helpers'

export const fetchInstrumentsApi = (query, page_number, limit) => {
	let config = {
		method: 'GET',
		url: `/instrument?query=${query}&page_number=${page_number}&limit=${limit}`,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const subscribeInstrumentApi = (instId) => {
	let config = {
		method: 'POST',
		url: `/subscribe`,
		data: { instrument_id: parseInt(instId) },
	}

	return api(config).then((resp) => {
		return resp
	})
}
export const deleteSubscribedInstrument = (instId) => {
	let config = {
		method: 'DELETE',
		url: `/subscribe/${instId}`,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const fetchSubscribedInstruments = (
	filter,
	page_number,
	limit,
	search_string
) => {
	let config = {
		method: 'GET',
		url: search_string
			? `/subscribe?filter=${filterFormatter(
					filter
			  )}&inst_info=True&page_number=${page_number}&limit=${limit}&search_string=${search_string}`
			: `/subscribe?filter=${filterFormatter(
					filter
			  )}&inst_info=True&page_number=${page_number}&limit=${limit}`,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const fetchHistoryData = (
	instrument_id,
	time_period,
	from_date,
	to_date
) => {
	let config = {
		method: 'GET',
		url: `/instrument/chart/history_data?instrument_id=${instrument_id}&time_period=${time_period}&from_date=${from_date}&to_date=${to_date}`,
	}
	return api(config).then((resp) => {
		return resp
	})
}

export const fetchTodayData = (instrument_id) => {
	let config = {
		method: 'GET',
		url: `/instrument/chart/today_data?instrument_id=${instrument_id}`,
	}
	return api(config).then((resp) => {
		return resp
	})
}

export const addMarketDepthClient = (instrument_id) => {
	let config = {
		method: 'GET',
		url: `/instrument/subscribe/${instrument_id}`,
	}
	return api(config).then((resp) => {
		return resp
	})
}

export const removeMarketDepthClient = (instrument_id) => {
	let config = {
		method: 'GET',
		url: `/instrument/unsubscribe/${instrument_id}`,
	}
	return api(config).then((resp) => {
		return resp
	})
}
