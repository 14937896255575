import * as actionTypes from '../actions'

const BacktestReducer = (state = {}, action) => {
	let newState
	switch (action.type) {
		case actionTypes.GET_BACKTEST_DATA_SUCCESS:
			newState = { ...state, data: action }
			break
		default:
			return state
	}
	return newState
}

export default BacktestReducer
