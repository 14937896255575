import React, { useContext, createContext, useState, useEffect } from 'react'
import Wampy from 'wampy'
import store from '../store'
import * as actionTypes from '../store/actions'

const wampyContext = createContext()

// Hook for child components to get the utils object ...
// ... and re-render when it changes.
export const useWampy = () => {
	return useContext(wampyContext)
}

// Provider hook that creates utils object and handles state
function useProvideWampy() {
	const [connection, setConnection] = useState(null)
	const [connectionError, setConnectionError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [marketDepth, setMarketDepth] = useState({})
	const [orderData, setOrderData] = useState(null)
	const [backtestData, setBacktestData] = useState(null)
	const [backtestStatus, setBacktestStatus] = useState(null)

	const subscribeToPairSyntheticPrices = (pair_id) => {
		connection.subscribe(`pair_${pair_id}`, (data) => {
			const jsonData = JSON.parse(data.argsDict.data)
			const ask =
				jsonData.synthetic_prices.ask_price === 'NaN'
					? 'No data available!'
					: jsonData.synthetic_prices.ask_price
			const bid =
				jsonData.synthetic_prices.bid_price === 'NaN'
					? 'No data available!'
					: jsonData.synthetic_prices.bid_price
			const { last_updated_time } = jsonData.synthetic_prices
			const syntheticData = {
				pair_id: jsonData.pair_id,
				ask: ask === 'CloseSocket' ? 'Stopped' : ask,
				bid: bid === 'CloseSocket' ? 'Stopped' : bid,
				last_updated_time:
					ask === 'CloseSocket' ? 'Stopped' : last_updated_time,
			}
			store.dispatch({
				type: actionTypes.GET_PAIR_SYNTHETIC_PRICES,
				data: syntheticData,
			})
		})
	}

	const subscribeToPairFills = (user_id) => {
		connection.subscribe(`pair_fills_${user_id}`, (data) => {
			store.dispatch({
				type: actionTypes.GET_PAIR_FILLS,
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToMarketDepth = (instId) => {
		connection.subscribe(`stream_${instId}`, {
			onSuccess(details) {
				console.log(
					`Successfully subscribed to topic: ${details.topic}`
				)
			},
			onError(errData) {
				console.log(`Subscription error:${errData.error}`)
			},
			onEvent(eventData) {
				setMarketDepth((prev) => {
					return {
						...prev,
						[instId]: JSON.parse(eventData.argsDict.data),
					}
				})
			},
		})
	}

	const subscribeToOrder = (user_id) => {
		connection.subscribe(`order_${user_id}`, (data) => {
			setOrderData({
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToOrderNewPage = (user_id) => {
		connection.subscribe(`order_${user_id}`, (data) => {
			store.dispatch({
				type: actionTypes.GET_ORDERS,
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToBacktest = (backtesting_id) => {
		connection.subscribe(`backtesting_${backtesting_id}`, (data) => {
			setBacktestData({
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToBacktestStatus = (user_id) => {
		connection.subscribe(`backtesting_${user_id}`, (data) => {
			setBacktestStatus({
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToPairLiveLogs = (pair_id) => {
		connection.subscribe(`zerodha_pair_feed_${pair_id}`, (data) => {
			store.dispatch({
				type: actionTypes.GET_PAIR_LIVE_LOGS,
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToActivePairUUID = (user_id) => {
		connection.subscribe(`pair_uuid_${user_id}`, (data) => {
			store.dispatch({
				type: actionTypes.GET_PAIR_ACTIVE_UUID,
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const subscribeToPairConfig = (user_id) => {
		connection.subscribe(`pair_config_${user_id}`, (data) => {
			store.dispatch({
				type: actionTypes.GET_PAIR_CONFIG,
				data: JSON.parse(data.argsDict.data),
			})
		})
	}

	const getNotifications = (user_id) => {
		connection.subscribe(`notification_${user_id}`, (data) => [
			store.dispatch({
				type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
				data: JSON.parse(data.argsDict.data),
			}),
		])
	}

	const subscribeToBulkPairSyntheticPrices = (user_id) => {
		connection.subscribe(`pair_synthetic_prices_${user_id}`, (data) => [
			store.dispatch({
				type: actionTypes.GET_PAIR_SYNTHETIC_PRICES,
				data: JSON.parse(data.argsDict.data).synthetic_prices,
			}),
		])
	}

	const subscribeToLiveMarketPrices = (user_id) => {
		connection.subscribe(`live_market_prices_${user_id}`, (data) => [
			store.dispatch({
				type: actionTypes.GET_LIVE_MARKET_PRICES,
				data: JSON.parse(data.argsDict.data),
			}),
		])
	}

	const unsubscribeTopic = (topic) => {
		if (topic.split('_')[0] === 'stream') {
			const data = marketDepth
			delete data[topic.split('_')[1]]
			setMarketDepth(data)
		}
		connection && connection.unsubscribe(topic)
	}

	useEffect(() => {
		const domain_name = process.env.REACT_APP_BASE_URL.replace(
			'https://',
			''
		)
		const newConnection = new Wampy(`wss://${domain_name}/ws`, {
			realm: 'realm1',
		})
		newConnection.options({
			onConnect: () => {
				console.log('wampy server connected')
				setConnectionError(false)
			},
			onClose() {
				console.log('connection closed')
				setConnectionError(true)
				setErrorMessage(
					'Connection failed. Please refresh or try again later.'
				)
			},
			onError() {
				console.log('Breakdown happened')
				setConnectionError(true)
				setErrorMessage(
					'Having trouble connecting to server. Please refresh or try again later.'
				)
			},
		})
		setConnection(newConnection)
	}, [])
	return {
		subscribeToPairSyntheticPrices,
		unsubscribeTopic,
		subscribeToPairFills,
		subscribeToMarketDepth,
		subscribeToOrder,
		subscribeToBacktest,
		subscribeToBacktestStatus,
		subscribeToPairLiveLogs,
		subscribeToActivePairUUID,
		subscribeToPairConfig,
		subscribeToOrderNewPage,
		getNotifications,
		subscribeToBulkPairSyntheticPrices,
		subscribeToLiveMarketPrices,
		connectionError,
		errorMessage,
		marketDepth,
		orderData,
		backtestData,
		backtestStatus,
	}
}

// Provider component that wraps your app and makes utils object ...
export function ProvideWampy({ children }) {
	const wampy = useProvideWampy()
	return (
		<wampyContext.Provider value={wampy}>{children}</wampyContext.Provider>
	)
}
