import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

export default ({ children, ...props }) => {
	return (
		<ThemeProvider theme={props.theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	)
}
