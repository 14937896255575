import * as actionTypes from '../actions'

const PairReducer = (state = {}, action) => {
	let newState
	switch (action.type) {
		case actionTypes.GET_PAIR_DATA_SUCCESS:
			newState = { ...state, data: action }
			break
		case actionTypes.GET_PAIR_SYNTHETIC_PRICES:
			const newData = {}
			if (action.data) {
				action.data.forEach((element) => {
					const ask =
						element.ask_price === 'NaN'
							? 'No data available!'
							: element.ask_price
					const bid =
						element.bid_price === 'NaN'
							? 'No data available!'
							: element.bid_price
					const { last_updated_time } = element
					const syntheticData = {
						pair_id: element.pair_id,
						ask: ask === 'CloseSocket' ? 'Stopped' : ask,
						bid: bid === 'CloseSocket' ? 'Stopped' : bid,
						last_updated_time:
							ask === 'CloseSocket'
								? 'Stopped'
								: last_updated_time,
					}
					newData[element.pair_id] = syntheticData
				})
			}
			newState = {
				...state,
				pairSyntheticPrices: {
					...state.pairSyntheticPrices,
					...newData,
				},
			}
			newState.type = action.type
			break
		case actionTypes.GET_PAIR_FILLS:
			newState = {
				...state,
				pairFills: {
					...state.pairFills,
					[action.data.pair_id]: {
						...(state.pairFills
							? state.pairFills[action.data.pair_id]
							: {}),
						[action.data.uuid]: {
							fills_received: action.data.buy_fills
								? action.data.buy_fills
								: action.data.sell_fills,
						},
					},
				},
			}
			newState.type = action.type
			newState.updated_pair = action.data.pair_id
			break
		case actionTypes.GET_PAIR_ACTIVE_UUID:
			newState = {
				...state,
				pairActiveUUID: {
					...state.pairActiveUUID,
					[action.data.pair_id]: {
						...(state.pairActiveUUID
							? state.pairActiveUUID[action.data.pair_id]
							: {}),
						[action.data.sized_up_side]: Number(
							action.data.new_uuid
						),
					},
				},
			}
			newState.type = action.type
			break
		case actionTypes.GET_PAIR_CONFIG:
			newState = {
				...state,
				pairConfig: {
					...state.pairConfig,
					[action.data.pair_id]: {
						uuid: Number(action.data.uuid),
						sized_up_side: action.data.side,
						config_data: action.data.config,
					},
				},
			}
			newState.type = action.type
			break
		case actionTypes.GET_PAIR_LIVE_LOGS:
			newState = {
				...state,
				pairLiveLogs: {
					...state.pairLiveLogs,
					[action.data.pair_id]: action.data.message,
				},
			}
			newState.type = action.type
			break
		case actionTypes.GET_LIVE_MARKET_PRICES:
			newState = {
				...state,
				live_market_prices: {
					...action.data.data,
				},
			}
			newState.type = action.type
			break
		default:
			return state
	}
	return newState
}

export default PairReducer
