import React from 'react'
import { Provider } from 'react-redux'
import store from './store'
import { ProvideUtils } from './contexts/utils'
import { ProvideAuth } from './contexts/Auth'
import theme from './theme'
import { BrowserRouter as Router } from 'react-router-dom'
import CustomThemeProvider from './customThemeProvider'
import Routes from './routes/routes'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ProvideWampy } from './contexts/Wampy'

function App() {
	Bugsnag.start({
		apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
		plugins: [new BugsnagPluginReact()],
		enabledReleaseStages: ['production', 'staging'],
	})

	const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

	return (
		<Provider store={store}>
			<ErrorBoundary>
				<ProvideUtils>
					<ProvideAuth>
						<ProvideWampy>
							<CustomThemeProvider theme={theme}>
								<Routes />
							</CustomThemeProvider>
						</ProvideWampy>
					</ProvideAuth>
				</ProvideUtils>
			</ErrorBoundary>
		</Provider>
	)
}

export default App
