import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
	note_text: {
		padding: '0.5rem',
		borderBottom: 'solid 1px',
		borderWidth: '1px',
		'&:hover': {
			background: '#f9f9f9',
		},
	},
	note_heading: {
		fontSize: '1rem',
		fontWeight: '600',
	},
	note_time: {
		fontSize: '0.8rem',
		marginLeft: '0.6rem',
	},
	note_msg: {
		fontSize: '0.8rem',
	},
	note_grid: {
		fontSize: '0.7rem',
	},
}))

const colorDict = {
	config_update: {
		bg: '#CBA387',
		color: '#fff',
	},
	pair_stop: {
		bg: '#e26962',
		color: '#fff',
	},
	order_error: {
		bg: '#e26962',
		color: '#fff',
	},
	fill_received: {
		bg: '#87CB8E',
		color: '#fff',
	},
	hedge_notification: {
		bg: '#e26962',
		color: '#fff',
	},
	undefined: {
		bg: '',
		color: '',
	},
}

const Message = ({ message }) => {
	const classes = useStyles()
	return (
		<Typography
			className={classes.note_text}
			style={{
				backgroundColor: colorDict[message.notification_type]
					? colorDict[message.notification_type].bg
					: '',
				color: colorDict[message.notification_type]
					? colorDict[message.notification_type].color
					: '',
			}}
		>
			<Typography className={classes.note_heading} display="inline">
				{message.heading}
			</Typography>
			<Typography className={classes.note_time} display="inline">
				{message.time || ''}
			</Typography>
			<Typography className={classes.note_msg}>{message.data}</Typography>
		</Typography>
	)
}

export default Message
