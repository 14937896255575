export const sidebarItemsData = [
	{
		tab_name: 'Subscription',
		permalink: 'admin/subscription',
		children: [],
	},
	{
		tab_name: 'Instruments',
		permalink: 'admin/instruments',
		children: [],
	},
	{
		tab_name: 'Market Depth',
		permalink: 'admin/market-depth',
		children: [],
	},
	{
		tab_name: 'Plot Chart',
		permalink: 'admin/plot-chart',
		children: [],
	},
	{
		tab_name: 'Legs',
		permalink: 'admin/legs',
		children: [],
	},
	{
		tab_name: 'Price Adjusters',
		permalink: 'admin/price-adjusters',
		children: [],
	},
	{
		tab_name: 'Groups',
		permalink: 'admin/groups',
		children: [],
	},
	{
		tab_name: 'Technical Indicators',
		permalink: 'admin/technical-indicators',
		children: [],
	},
	{
		tab_name: 'Pairs',
		permalink: 'admin/pairs',
		children: [],
	},
	{
		tab_name: 'Orders',
		permalink: 'admin/orders',
		children: [],
	},
	{
		tab_name: 'Audit Logs',
		permalink: 'admin/audit-logs',
		children: [],
	},
	{
		tab_name: 'PortFolio',
		children: [
			{
				tab_name: 'Holdings',
				permalink: 'admin/portfolio/holdings',
				children: [],
			},
			{
				tab_name: 'Positions',
				permalink: 'admin/portfolio/positions',
				children: [],
			},
		],
	},
	{
		tab_name: 'Backorder Testing',
		permalink: 'admin/backorder',
		children: [],
	},
	// {
	// 	tab_name: 'Logs',
	// 	children: [
	// 		{
	// 			tab_name: 'Legs',
	// 			permalink: 'admin/logs/leg',
	// 			children: [],
	// 		},
	// 		{
	// 			tab_name: 'Group',
	// 			permalink: 'admin/logs/group',
	// 			children: [],
	// 		},
	// 		{
	// 			tab_name: 'Pairs',
	// 			permalink: 'admin/logs/pair',
	// 			children: [],
	// 		},
	// 	],
	// },
]

export const editingPagesRoutes = []

export const listingTableHeadData = {
	instrument: [
		'Name',
		'Instrument Token',
		'Trading Symbol',
		'Strike',
		'Type',
		'Segment',
		'Exchange',
	],
	subscribed_instrument: [
		'Name',
		'Token',
		'Trading Symbol',
		'Strike',
		'Type',
		'Segment',
		'Exchange',
	],
	holding: [
		'Instrument Token',
		'Trading Symbol',
		'Exchange',
		'Product',
		'Quantity',
		'Last Price',
	],
	position: [
		'Instrument Token',
		'Exchange',
		'TradingSymbol',
		'Product',
		'Multiplier',
		'Quantity',
	],
	leg: [
		'Name',
		'Instrument Token',
		'Market',
		'Spread Ratio',
		'Market Depth',
		'Settlement Price',
	],
	group: [
		'Group Name',
		'Total Size Showing',
		'Quoting Mode',
		'Group Constant',
		'Price Adjuster',
	],
	price_adjuster: ['Adjuster Name', 'Adjuster Type'],
	pair: [
		'Pair Name',
		'Buy Side',
		'Bid Price',
		'Ask Price',
		'Sell Side',
		'Last Updated',
	],
	backorder: [
		'Pair Name',
		'Backorder Testing Name',
		'Start Date',
		'End Date',
		'Status',
	],
	orders: [
		'Order Change Time',
		'Zerodha Timestamp',
		'Name',
		'Trading Symbol',
		'Exchange',
		'Order Type',
		'Price',
		'Quantity',
		'Transaction Type',
		'Status',
		'Order Class',
	],
	order_history: [
		'Order Timestamp',
		'Exchange Timestamp',
		'Price',
		'Quantity',
		'Status',
	],
	admin_user_list: ['Name', 'Email'],
	admin_login_history: ['Email', 'Login Time'],
	instrument_live_data: ['Name', 'Bid Price', 'Ask Price'],
	technical_indicator: ['Name', 'Number of Data Points', 'Periodicity'],
}

export const dataOrders = {
	instrument: [
		'name',
		'instrument_token',
		'tradingsymbol',
		'strike',
		'instrument_type',
		'segment',
		'exchange',
	],
	subscribed_instrument: [
		['inst_info', 'name'],
		['inst_info', 'instrument_token'],
		['inst_info', 'tradingsymbol'],
		['inst_info', 'strike'],
		['inst_info', 'instrument_type'],
		['inst_info', 'segment'],
		['inst_info', 'exchange'],
	],
	holding: [
		'instrument_token',
		'tradingsymbol',
		'exchange',
		'product',
		'quantity',
		'last_price',
	],
	position: [
		'instrument_token',
		'exchange',
		'tradingsymbol',
		'product',
		'multiplier',
		'quantity',
	],
	leg: [
		'leg_name',
		'instrument_id',
		'market',
		'spread_ratio',
		'market_depth',
		'settlement_price',
	],
	group: [
		'group_name',
		'total_size_showing',
		'qouting_mode',
		'group_constant',
		'price_adjuster_name',
	],
	price_adjuster: ['name', 'type'],
	pair: [
		'pair_name',
		'buy_side',
		'bid',
		'ask',
		'sell_side',
		'last_updated_time',
	],
	backorder: [
		'pair_name',
		'backtesting_name',
		'from_date',
		'to_date',
		'status',
	],
	orders: [
		'order_modification_timestamp',
		'zerodha_update_timestamp',
		'instrument_name',
		'tradingsymbol',
		'exchange',
		'order_type',
		'price',
		'quantity',
		'transaction_type',
		'status',
		'hedge_order',
	],
	order_history: [
		'order_timestamp',
		'exchange_update_timestamp',
		'price',
		'quantity',
		'status',
	],
	admin_user_list: ['name', 'email'],
	admin_login_history: ['email', 'login_time'],
	instrument_live_data: ['name', 'bid_price', 'ask_price'],
	technical_indicator: ['name', 'number_of_data_points', 'periodicity'],
}

export const listingActions = {
	instrument: [],
	subscribed_instrument: [],
	holding: [{ label: 'view', permalink: '/admin/holding/view' }],
	position: [{ label: 'view', permalink: '/admin/position/view' }],
	leg: [],
	group: [],
	price_adjuster: [],
	pair: [],
	backorder: [],
	auditLogs: [],
	orders: [],
	order_history: [],
	admin_user_list: [],
	admin_login_history: [],
	instrument_live_data: [],
	technical_indicator: [],
}

export const priceAdjusterDict = {
	HedgeSide: ['hedgeback_ticks_away', 'bracket_order'],
	ActiveSide: [
		'basic_sniper',
		'quote_away',
		'quote_with_others',
		'minimum_volume_order',
	],
}

export const orderOptions = [
	{
		name: 'variety',
		data: [
			{
				label: 'Regular order',
				value: 'regular',
			},
			{
				label: 'After Market Order',
				value: 'amo',
			},
			{
				label: 'Bracket Order',
				value: 'bo',
			},
			{
				label: 'Cover Order',
				value: 'co',
			},
		],
	},
	{
		name: 'order_type',
		data: [
			{
				label: 'Market order',
				value: 'MARKET',
			},
			{
				label: 'Limit Order',
				value: 'LIMIT',
			},
			{
				label: 'Stoploss order',
				value: 'SL',
			},
			{
				label: 'Stoploss-market-order',
				value: 'SL-M',
			},
		],
	},
	{
		name: 'product',
		data: [
			{
				label: 'Cash & Carry for equity',
				value: 'CNC',
			},
			{
				label: 'Normal for futures and options',
				value: 'NRML',
			},
			{
				label: 'Margin Intraday Squareoff for futures and options',
				value: 'MIS',
			},
		],
	},
	{
		name: 'validity',
		data: [
			{
				label: 'Regular order',
				value: 'DAY',
			},
			{
				label: 'Immediate or Cancel',
				value: 'IOC',
			},
		],
	},
]
// mock data
export const HoldingMockData = [
	{
		exchange: 'BSE',
		exchange_token: '538593',
		expiry: '',
		instrument_token: 137879812,
		instrument_type: 'EQ',
		last_price: 0,
		name: 'RELIANCE MUTUAL FUND- RELIANCE',
		segment: 'BSE',
		tradingsymbol: 'NCAPBUILAD',
		quantity: 25,
		amount: 3000,
	},
	{
		exchange: 'NSE',
		exchange_token: '538593',
		expiry: '',
		instrument_token: 137879812,
		instrument_type: 'EQ',
		last_price: 0,
		name: 'RELIANCE PETROLIUM',
		segment: 'BSE',
		tradingsymbol: 'NCAPBUILAD',
		quantity: 100,
		amount: 10000,
	},
	{
		exchange: 'BSE',
		exchange_token: '238193',
		expiry: '',
		instrument_token: 137878712,
		instrument_type: 'EQ',
		last_price: 0,
		name: 'RELIANCE',
		segment: 'BSE',
		tradingsymbol: 'NCAPBUILAD',
		quantity: 150,
		amount: 5000,
	},
	{
		exchange: 'NSE',
		exchange_token: '538593',
		expiry: '',
		instrument_token: 137879812,
		instrument_type: 'EQ',
		last_price: 0,
		name: 'BHARAT PETROLIUM',
		segment: 'BSE',
		tradingsymbol: 'NCAPBUILAD',
		quantity: 50,
		amount: 8000,
	},
	{
		exchange: 'NSE',
		exchange_token: '538593',
		expiry: '',
		instrument_token: 137879815,
		instrument_type: 'EQ',
		last_price: 0,
		name: 'RELIANCE DIGITAL',
		segment: 'BSE',
		tradingsymbol: 'NCAPBUILAD',
		quantity: 90,
		amount: 14000,
	},
	{
		exchange: 'NSE',
		exchange_token: '538593',
		expiry: '',
		instrument_token: 137879812,
		instrument_type: 'EQ',
		last_price: 0,
		name: 'MRF',
		segment: 'BSE',
		tradingsymbol: 'NCAPBUILAD',
		quantity: 2,
		amount: 180000,
	},
]

export const PositionMockData = {
	net: [
		{
			tradingsymbol: 'NIFTY15DEC9500CE',
			exchange: 'NFO',
			instrument_token: 41453,
			product: 'NRML',
			quantity: 100,
			overnight_quantity: 100,
			multiplier: 1,
			average_price: 3.475,
			close_price: 0.75,
			last_price: 0.75,
			value: 75.0,
			pnl: 272.5,
			m2m: 0.0,
			unrealised: 0.0,
			realised: 0.0,
			buy_quantity: 0,
			buy_price: 0,
			buy_value: 0.0,
			buy_m2m: 0.0,
			day_buy_quantity: 0,
			day_buy_price: 0,
			day_buy_value: 0.0,
			day_sell_quantity: 0,
			day_sell_price: 0,
			day_sell_value: 0.0,
			sell_quantity: 100,
			sell_price: 3.475,
			sell_value: 347.5,
			sell_m2m: 75.0,
		},
		{
			tradingsymbol: 'NIFTY15DEC7500CE',
			exchange: 'BSE',
			instrument_token: 51453,
			product: 'NRML',
			quantity: 200,
			overnight_quantity: 100,
			multiplier: 5,
			average_price: 3.475,
			close_price: 0.75,
			last_price: 0.75,
			value: 75.0,
			pnl: 272.5,
			m2m: 0.0,
			unrealised: 0.0,
			realised: 0.0,
			buy_quantity: 0,
			buy_price: 0,
			buy_value: 0.0,
			buy_m2m: 0.0,
			day_buy_quantity: 0,
			day_buy_price: 0,
			day_buy_value: 0.0,
			day_sell_quantity: 0,
			day_sell_price: 0,
			day_sell_value: 0.0,
			sell_quantity: 100,
			sell_price: 3.475,
			sell_value: 347.5,
			sell_m2m: 75.0,
		},
		{
			tradingsymbol: 'NIFTY15DEC7600CE',
			exchange: 'BSE',
			instrument_token: 41453,
			product: 'NRML',
			quantity: 150,
			overnight_quantity: 100,
			multiplier: 1,
			average_price: 3.475,
			close_price: 0.75,
			last_price: 0.75,
			value: 75.0,
			pnl: 272.5,
			m2m: 0.0,
			unrealised: 0.0,
			realised: 0.0,
			buy_quantity: 0,
			buy_price: 0,
			buy_value: 0.0,
			buy_m2m: 0.0,
			day_buy_quantity: 0,
			day_buy_price: 0,
			day_buy_value: 0.0,
			day_sell_quantity: 0,
			day_sell_price: 0,
			day_sell_value: 0.0,
			sell_quantity: 100,
			sell_price: 3.475,
			sell_value: 347.5,
			sell_m2m: 75.0,
		},
		{
			tradingsymbol: 'NIFTY15DEC9500CE',
			exchange: 'NFO',
			instrument_token: 41453,
			product: 'NRML',
			quantity: 180,
			overnight_quantity: 100,
			multiplier: 3,
			average_price: 3.475,
			close_price: 0.75,
			last_price: 0.75,
			value: 75.0,
			pnl: 272.5,
			m2m: 0.0,
			unrealised: 0.0,
			realised: 0.0,
			buy_quantity: 0,
			buy_price: 0,
			buy_value: 0.0,
			buy_m2m: 0.0,
			day_buy_quantity: 0,
			day_buy_price: 0,
			day_buy_value: 0.0,
			day_sell_quantity: 0,
			day_sell_price: 0,
			day_sell_value: 0.0,
			sell_quantity: 100,
			sell_price: 3.475,
			sell_value: 347.5,
			sell_m2m: 75.0,
		},
		{
			tradingsymbol: 'NIFTY15DEC9500CE',
			exchange: 'BSE',
			instrument_token: 41453,
			product: 'NRML',
			quantity: 220,
			overnight_quantity: 100,
			multiplier: 2,
			average_price: 3.475,
			close_price: 0.75,
			last_price: 0.75,
			value: 75.0,
			pnl: 272.5,
			m2m: 0.0,
			unrealised: 0.0,
			realised: 0.0,
			buy_quantity: 0,
			buy_price: 0,
			buy_value: 0.0,
			buy_m2m: 0.0,
			day_buy_quantity: 0,
			day_buy_price: 0,
			day_buy_value: 0.0,
			day_sell_quantity: 0,
			day_sell_price: 0,
			day_sell_value: 0.0,
			sell_quantity: 100,
			sell_price: 3.475,
			sell_value: 347.5,
			sell_m2m: 75.0,
		},
		{
			tradingsymbol: 'NIFTY15DEC9500CE',
			exchange: 'NFO',
			instrument_token: 41453,
			product: 'NRML',
			quantity: 300,
			overnight_quantity: 100,
			multiplier: 1,
			average_price: 3.475,
			close_price: 0.75,
			last_price: 0.75,
			value: 75.0,
			pnl: 272.5,
			m2m: 0.0,
			unrealised: 0.0,
			realised: 0.0,
			buy_quantity: 0,
			buy_price: 0,
			buy_value: 0.0,
			buy_m2m: 0.0,
			day_buy_quantity: 0,
			day_buy_price: 0,
			day_buy_value: 0.0,
			day_sell_quantity: 0,
			day_sell_price: 0,
			day_sell_value: 0.0,
			sell_quantity: 100,
			sell_price: 3.475,
			sell_value: 347.5,
			sell_m2m: 75.0,
		},
	],
	day: [],
}

export const LegsMockData = [
	{
		instrument_id: 0,
		market: '',
		leg_name: 'First',
		spread_ratio: 0,
		market_depth: 0,
		coefficient: 0,
		constant: 0,
		payup_tick: '',
		hedge_round: true,
		smart_qoute: 0,
		queue_holder: 0,
		settlement_price: 0.0,
		import_type: '',
		pricing_only: true,
		use_micro_price: 0,
		use_averageleanprice: 0,
		is_ioi: true,
	},
	{
		instrument_id: 0,
		market: '',
		leg_name: 'Second',
		spread_ratio: 0,
		market_depth: 0,
		coefficient: 0,
		constant: 0,
		payup_tick: '',
		hedge_round: true,
		smart_qoute: 0,
		queue_holder: 0,
		settlement_price: 0.0,
		import_type: '',
		pricing_only: true,
		use_micro_price: 0,
		use_averageleanprice: 0,
		is_ioi: true,
	},
	{
		instrument_id: 0,
		market: '',
		leg_name: 'Third',
		spread_ratio: 0,
		market_depth: 0,
		coefficient: 0,
		constant: 0,
		payup_tick: '',
		hedge_round: true,
		smart_qoute: 0,
		queue_holder: 0,
		settlement_price: 0.0,
		import_type: '',
		pricing_only: true,
		use_micro_price: 0,
		use_averageleanprice: 0,
		is_ioi: true,
	},
	{
		instrument_id: 0,
		market: '',
		leg_name: 'Fourth',
		spread_ratio: 0,
		market_depth: 0,
		coefficient: 0,
		constant: 0,
		payup_tick: '',
		hedge_round: true,
		smart_qoute: 0,
		queue_holder: 0,
		settlement_price: 0.0,
		import_type: '',
		pricing_only: true,
		use_micro_price: 0,
		use_averageleanprice: 0,
		is_ioi: true,
	},
]

export const GroupsMockData = [
	{
		total_size_showing: 2,
		qouting_mode: 'first',
		group_constant: 0,
		active_quoting: false,
		share_sublean_liquidity: false,
		refresh_on_active: false,
		price_adjuster: 'NA',
		member_legs: [],
	},
	{
		total_size_showing: 5,
		qouting_mode: 'second',
		group_constant: 0,
		active_quoting: false,
		share_sublean_liquidity: false,
		refresh_on_active: false,
		price_adjuster: 'NA',
		member_legs: [],
	},
	{
		total_size_showing: 3,
		qouting_mode: 'third',
		group_constant: 0,
		active_quoting: false,
		share_sublean_liquidity: false,
		refresh_on_active: false,
		price_adjuster: 'NA',
		member_legs: [],
	},
	{
		total_size_showing: 2,
		qouting_mode: 'fourth',
		group_constant: 0,
		active_quoting: false,
		share_sublean_liquidity: false,
		refresh_on_active: false,
		price_adjuster: 'NA',
		member_legs: [],
	},
	{
		total_size_showing: 6,
		qouting_mode: 'fifth',
		group_constant: 0,
		active_quoting: false,
		share_sublean_liquidity: false,
		refresh_on_active: false,
		price_adjuster: 'NA',
		member_legs: [],
	},
	{
		total_size_showing: 1,
		qouting_mode: 'sixth',
		group_constant: 0,
		active_quoting: false,
		share_sublean_liquidity: false,
		refresh_on_active: false,
		price_adjuster: 'NA',
		member_legs: [],
	},
]

export const PriceAdjusterOptions = [
	{ label: 'Basic Sniper', value: 'basic_sniper' },
	{ label: 'Quote Away', value: 'quote_away' },
	{ label: 'Quote With Others', value: 'quote_with_others' },
	{ label: 'Minimum Volume Order', value: 'minimum_volume_order' },
	{ label: 'Hedgeback Ticks Away', value: 'hedgeback_ticks_away' },
	{ label: 'Bracket Order', value: 'bracket_order' },
	{ label: 'Same Side Quote Away', value: 'same_side_quote_away' },
	// { label: 'Market Maker', value: 'market_maker' },
	// { label: 'Quote Away Percentage', value: 'quote_away_percentage' },
]

export const PriceAdjusterMockData = [
	{
		id: 100,
		name: 'first',
		type: 'basic_sniper',
		num_ticks_away_to_qoute: 0,
		allow_empty_opposite: false,
		ticks_from_opposite_side: 0,
		num_contracts_qoute_with: 0,
		num_ticks_qoute_with: 0,
		ticks_to_alter_qoute: 0,
		allow_cross_when_with_others: false,
		allow_cross_when_not_with_others: false,
		market_maker_num_contracts: '',
		allow_cross: false,
		min_quantity_available: 0,
		depth_threshold: 0,
		tick_filter: 0,
		cap_size_to_market: false,
		signal_adj_filter: false,
		aggression_slop_ticks: 0,
		sniper_cap_ticks: 0,
		sniper_cap_average_quantity: 0,
		num_ticks_away: 0,
		percentage_away: 0,
		aggresive_ALP_quantity: 0,
		extra_data: {},
		passive_ALP_quantity: 0,
	},
	{
		id: 200,
		name: 'second',
		type: 'basic_sniper',
		num_ticks_away_to_qoute: 0,
		allow_empty_opposite: false,
		ticks_from_opposite_side: 0,
		num_contracts_qoute_with: 0,
		num_ticks_qoute_with: 0,
		ticks_to_alter_qoute: 0,
		allow_cross_when_with_others: false,
		allow_cross_when_not_with_others: false,
		market_maker_num_contracts: '',
		allow_cross: false,
		min_quantity_available: 0,
		depth_threshold: 0,
		tick_filter: 0,
		cap_size_to_market: false,
		signal_adj_filter: false,
		aggression_slop_ticks: 0,
		sniper_cap_ticks: 0,
		sniper_cap_average_quantity: 0,
		num_ticks_away: 0,
		percentage_away: 0,
		aggresive_ALP_quantity: 0,
		extra_data: {},
		passive_ALP_quantity: 0,
	},
]

export const MarketDepthData = {
	buy: [
		{
			quantity: 1,
			price: 0.1,
			orders: 1,
		},
		{
			quantity: 2,
			price: 0.2,
			orders: 2,
		},
		{
			quantity: 3,
			price: 0.3,
			orders: 3,
		},
		{
			quantity: 4,
			price: 0.4,
			orders: 4,
		},
		{
			quantity: 5,
			price: 0.5,
			orders: 5,
		},
	],
	sell: [
		{
			quantity: 1,
			price: 0.1,
			orders: 1,
		},
		{
			quantity: 2,
			price: 0.2,
			orders: 2,
		},
		{
			quantity: 3,
			price: 0.3,
			orders: 3,
		},
		{
			quantity: 4,
			price: 0.4,
			orders: 4,
		},
		{
			quantity: 5,
			price: 0.5,
			orders: 5,
		},
	],
}

export const timeIntervalOptions = [
	{ label: '1 Minute', value: 'minute' },
	{ label: '1 Day', value: 'day' },
	{ label: '3 Minute', value: '3minute' },
	{ label: '5 Minute', value: '5minute' },
	{ label: '10 Minute', value: '10minute' },
	{ label: '15 Minute', value: '15minute' },
	{ label: '30 Minute', value: '30minute' },
	{ label: '60 Minute', value: '60minute' },
]

export const backorderJobsMockData = [
	{
		pair_id: 38,
		start_date: '2021-06-07',
		end_date: '2021-06-23',
		time_interval: '3minute',
	},
	{
		pair_id: 38,
		start_date: '2021-06-08',
		end_date: '2021-06-21',
		time_interval: '1minute',
	},
	{
		pair_id: 38,
		start_date: '2021-06-09',
		end_date: '2021-06-20',
		time_interval: '5minute',
	},
	{
		pair_id: 38,
		start_date: '2021-06-02',
		end_date: '2021-06-19',
		time_interval: '3minute',
	},
	{
		pair_id: 38,
		start_date: '2021-06-01',
		end_date: '2021-06-18',
		time_interval: '15minute',
	},
]

export const mockCandleData = {
	series: [
		{
			name: 'OHLC',
			type: 'candlestick',
			data: [
				{
					x: new Date(1538854200000),
					y: [6593.13, 6596.01, 6590, 6593.34],
				},
				{
					x: new Date(1538856000000),
					y: [6593.34, 6604.76, 6582.63, 6593.86],
				},
				{
					x: new Date(1538857800000),
					y: [6593.86, 6604.28, 6586.57, 6600.01],
				},
				{
					x: new Date(1538859600000),
					y: [6601.81, 6603.21, 6592.78, 6596.25],
				},
				{
					x: new Date(1538861400000),
					y: [6596.25, 6604.2, 6590, 6602.99],
				},
				{
					x: new Date(1538863200000),
					y: [6602.99, 6606, 6584.99, 6587.81],
				},
				{
					x: new Date(1538865000000),
					y: [6587.81, 6595, 6583.27, 6591.96],
				},
				{
					x: new Date(1538866800000),
					y: [6591.97, 6596.07, 6585, 6588.39],
				},
				{
					x: new Date(1538868600000),
					y: [6587.6, 6598.21, 6587.6, 6594.27],
				},
				{
					x: new Date(1538870400000),
					y: [6596.44, 6601, 6590, 6596.55],
				},
				{
					x: new Date(1538872200000),
					y: [6598.91, 6605, 6596.61, 6600.02],
				},
				{
					x: new Date(1538874000000),
					y: [6600.55, 6605, 6589.14, 6593.01],
				},
				{
					x: new Date(1538875800000),
					y: [6593.15, 6605, 6592, 6603.06],
				},
				{
					x: new Date(1538877600000),
					y: [6603.07, 6604.5, 6599.09, 6603.89],
				},
				{
					x: new Date(1538879400000),
					y: [6604.44, 6604.44, 6600, 6603.5],
				},
				{
					x: new Date(1538881200000),
					y: [6603.5, 6603.99, 6597.5, 6603.86],
				},
				{
					x: new Date(1538883000000),
					y: [6603.85, 6605, 6600, 6604.07],
				},
				{
					x: new Date(1538884800000),
					y: [6604.98, 6606, 6604.07, 6606],
				},
			],
		},
	],
}
export const mockBar = {
	series: [
		{
			name: 'Volume',
			data: [
				{
					x: new Date(1538854200000),
					y: 6593.13,
				},
				{
					x: new Date(1538856000000),
					y: 5593.34,
				},
				{
					x: new Date(1538857800000),
					y: 6593.86,
				},
				{
					x: new Date(1538859600000),
					y: 4601.81,
				},
				{
					x: new Date(1538861400000),
					y: 6596.25,
				},
				{
					x: new Date(1538863200000),
					y: 6002.99,
				},
				{
					x: new Date(1538865000000),
					y: 6587.81,
				},
				{
					x: new Date(1538866800000),
					y: 6091.97,
				},
				{
					x: new Date(1538868600000),
					y: 6587.6,
				},
				{
					x: new Date(1538870400000),
					y: 6096.44,
				},
				{
					x: new Date(1538872200000),
					y: 6598.91,
				},
				{
					x: new Date(1538874000000),
					y: 6000.55,
				},
				{
					x: new Date(1538875800000),
					y: 6593.15,
				},
				{
					x: new Date(1538877600000),
					y: 6003.07,
				},
				{
					x: new Date(1538879400000),
					y: 6604.44,
				},
				{
					x: new Date(1538881200000),
					y: 6003.5,
				},
				{
					x: new Date(1538883000000),
					y: 5603.85,
				},
				{
					x: new Date(1538884800000),
					y: 4604.98,
				},
			],
		},
	],
}

export const mockLineData = {
	series: [
		{
			name: 'Line',
			data: [
				{
					x: new Date(1538854200000),
					y: 6593.13,
				},
				{
					x: new Date(1538856000000),
					y: 5593.34,
				},
				{
					x: new Date(1538857800000),
					y: 6593.86,
				},
				{
					x: new Date(1538859600000),
					y: 4601.81,
				},
				{
					x: new Date(1538861400000),
					y: 6596.25,
				},
				{
					x: new Date(1538863200000),
					y: 6002.99,
				},
				{
					x: new Date(1538865000000),
					y: 6587.81,
				},
				{
					x: new Date(1538866800000),
					y: 6091.97,
				},
				{
					x: new Date(1538868600000),
					y: 6587.6,
				},
				{
					x: new Date(1538870400000),
					y: 6096.44,
				},
				{
					x: new Date(1538872200000),
					y: 6598.91,
				},
				{
					x: new Date(1538874000000),
					y: 6000.55,
				},
				{
					x: new Date(1538875800000),
					y: 6593.15,
				},
				{
					x: new Date(1538877600000),
					y: 6003.07,
				},
				{
					x: new Date(1538879400000),
					y: 6604.44,
				},
				{
					x: new Date(1538881200000),
					y: 6003.5,
				},
				{
					x: new Date(1538883000000),
					y: 5603.85,
				},
				{
					x: new Date(1538884800000),
					y: 4604.98,
				},
			],
		},
	],
}

export const ordersMockData = [
	{
		name: 'RELIANCE INDUSTRIES',
		instrument_token: '738561',
		tradingsymbol: 'RELIANCE',
		exchange: 'NSE',
		quantity: '23.1',
		order_type: 'Market Order',
		price: '3241',
		transaction_type: 'Buy',
		status: 'Open',
		triggered_by: 'user',
	},
	{
		name: 'RELIANCE MUTUAL FUND- RELIANCE',
		instrument_token: '137879812',
		tradingsymbol: 'NCAPBUILAD',
		exchange: 'NSE',
		quantity: '11.32',
		order_type: 'Market Order',
		price: '242',
		transaction_type: 'Buy',
		status: 'Pending',
		triggered_by: 'user',
	},
	{
		name: 'EURINR',
		instrument_token: '271336966',
		tradingsymbol: 'EURINR21SEPFUT',
		exchange: 'NSE',
		quantity: '10',
		order_type: 'Market Order',
		price: '250',
		transaction_type: 'Sell',
		status: 'Completed',
		triggered_by: 'user',
	},
	{
		name: 'B2B SOFTWARE TECHNOLOGIES.',
		instrument_token: '136004612',
		tradingsymbol: 'B2BSOFT',
		exchange: 'NSE',
		quantity: '5.61',
		order_type: 'Market Order',
		price: '150',
		transaction_type: 'Sell',
		status: 'Rejected',
		triggered_by: 'user',
	},
	{
		name: 'INDIA VIX',
		instrument_token: '264969',
		tradingsymbol: 'INDIA VIX',
		exchange: 'NSE',
		quantity: '30.45',
		order_type: 'Market Order',
		price: '1000',
		transaction_type: 'Buy',
		status: 'Open',
		triggered_by: 'user',
	},
	{
		name: 'RELIANCE INDUSTRIES',
		instrument_token: '738561',
		tradingsymbol: 'RELIANCE',
		exchange: 'NSE',
		quantity: '16.21',
		order_type: 'Market Order',
		price: '2051',
		transaction_type: 'Sell',
		status: 'Pending',
		triggered_by: 'pair',
	},
	{
		name: 'RELIANCE MUTUAL FUND- RELIANCE',
		instrument_token: '137879812',
		tradingsymbol: 'NCAPBUILAD',
		exchange: 'NSE',
		quantity: '31.52',
		order_type: 'Market Order',
		price: '2042',
		transaction_type: 'Sell',
		status: 'Rejected',
		triggered_by: 'pair',
	},
	{
		name: 'EURINR',
		instrument_token: '271336966',
		tradingsymbol: 'EURINR21SEPFUT',
		exchange: 'NSE',
		quantity: '31',
		order_type: 'Market Order',
		price: '2050',
		transaction_type: 'Buy',
		status: 'Completed',
		triggered_by: 'pair',
	},
	{
		name: 'B2B SOFTWARE TECHNOLOGIES.',
		instrument_token: '136004612',
		tradingsymbol: 'B2BSOFT',
		exchange: 'NSE',
		quantity: '6.52',
		order_type: 'Market Order',
		price: '250',
		transaction_type: 'Buy',
		status: 'Completed',
		triggered_by: 'pair',
	},
	{
		name: 'INDIA VIX',
		instrument_token: '264969',
		tradingsymbol: 'INDIA VIX',
		exchange: 'NSE',
		quantity: '40.65',
		order_type: 'Market Order',
		price: '2100',
		transaction_type: 'Buy',
		status: 'Open',
		triggered_by: 'pair',
	},
]
export const orderMockDetails = {
	order_id: '52165000',
	parent_order_id: '6451',
	variety: 'Mock',
	exchange: 'Mock',
	tradingsymbol: 'Mock',
	quantity: 'Mock',
	product: 'Mock',
	order_type: 'Mock',
	price: 'Mock',
	validity: 'Mock',
	disclosed_quantity: 'Mock',
	trigger_price: 'Mock',
	squareoff: 'Mock',
	stoploss: 'Mock',
	trailing_stoploss: 'Mock',
	tag: 'Mock',
	direction: 'Mock',
	transaction_type: 'Mock',
	status: 'Mock',
	other_data: 'Mock',
	status_message: 'Mock',
	market_protection: 'Mock',
	pending_quantity: 'Mock',
	instrument_token: 'Mock',
	exchange_order_id: 'Mock',
	placed_by: 'Mock',
	filled_quantity: 'Mock',
	average_price: 'Mock',
	order_timestamp: 'Mock',
	exchange_timestamp: 'Mock',
}

export const orderDetailsfields = [
	'order_id',
	'instrument_name',
	'instrument_token',
	'tradingsymbol',
	'variety',
	'exchange',
	'order_type',
	'transaction_type',
	'quantity',
	'price',
]

export const order_type = {
	limit: 'Limit Order',
	market: 'Market Order',
	sl: 'SL Order',
	slm: 'SLM Order',
}

export const product_type = {
	nrml: 'Normal Product',
	bo: 'BO Product',
	cnc: 'CNC Product',
	co: 'CO Product',
	mis: 'MIS Product',
}

export const hide_sidebar_url = ['admin/instrument-live-data*']

export const temp = {
	resting_order: [
		{
			meta_data: {
				order_id: '5125567542',
				order_timestamp: '2021-11-10T14:22:45',
				order_price: 206,
				trading_symbol: 'restingtradingsymbol',
			},
			chart_data: [
				{
					best_offer: 200,
					best_bid: 205,
					timestamp: '2021-11-10T14:22:44',
					depth: [],
				},
				{
					best_offer: 203,
					best_bid: 207,
					timestamp: '2021-11-10T14:22:46',
					depth: [],
				},
				{
					best_offer: 202,
					best_bid: 203,
					timestamp: '2021-11-10T14:22:47',
					depth: [],
				},
				{
					best_offer: 206,
					best_bid: 203,
					timestamp: '2021-11-10T14:22:48',
					depth: [],
				},
				{
					best_offer: 201,
					best_bid: 209,
					timestamp: '2021-11-10T14:22:49',
					depth: [],
				},
				{
					best_offer: 205,
					best_bid: 201,
					timestamp: '2021-11-10T14:22:50',
					depth: [],
				},
			],
		},
	],
	hedge_order: [
		{
			meta_data: {
				order_id: '5125567541',
				order_timestamp: '2021-11-10T14:22:46',
				order_price: 208,
				trading_symbol: 'hedge1tradingsymbol',
			},
			chart_data: [
				{
					best_offer: 200,
					best_bid: 205,
					timestamp: '2021-11-10T14:22:44',
					depth: [],
				},
				{
					best_offer: 203,
					best_bid: 207,
					timestamp: '2021-11-10T14:22:46',
					depth: [],
				},
				{
					best_offer: 202,
					best_bid: 203,
					timestamp: '2021-11-10T14:22:47',
					depth: [],
				},
				{
					best_offer: 206,
					best_bid: 203,
					timestamp: '2021-11-10T14:22:48',
					depth: [],
				},
				{
					best_offer: 201,
					best_bid: 209,
					timestamp: '2021-11-10T14:22:49',
					depth: [],
				},
				{
					best_offer: 205,
					best_bid: 201,
					timestamp: '2021-11-10T14:22:50',
					depth: [],
				},
			],
		},
		{
			meta_data: {
				order_id: '5125567540',
				order_timestamp: '2021-11-10T14:22:48',
				order_price: 207,
				trading_symbol: 'hedge2tradingsymbol',
			},
			chart_data: [
				{
					best_offer: 200,
					best_bid: 205,
					timestamp: '2021-11-10T14:22:44',
					depth: [],
				},
				{
					best_offer: 203,
					best_bid: 207,
					timestamp: '2021-11-10T14:22:46',
					depth: [],
				},
				{
					best_offer: 202,
					best_bid: 203,
					timestamp: '2021-11-10T14:22:47',
					depth: [],
				},
				{
					best_offer: 206,
					best_bid: 203,
					timestamp: '2021-11-10T14:22:48',
					depth: [],
				},
				{
					best_offer: 201,
					best_bid: 209,
					timestamp: '2021-11-10T14:22:49',
					depth: [],
				},
				{
					best_offer: 205,
					best_bid: 201,
					timestamp: '2021-11-10T14:22:50',
					depth: [],
				},
			],
		},
	],
}

export const TAList = {
	// Trend Indicators
	Float: {
		value: 'Float',
		attributes: ['float'],
		show_ohlc_data_component: false,
	},
	SMAIndicator: {
		value: 'SMAIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},
	STCIndicator: {
		value: 'STCIndicator',
		attributes: [
			'window_slow',
			'window_fast',
			'cycle',
			'smooth1',
			'smooth2',
		],
		show_ohlc_data_component: true,
	},
	MACDIndicator: {
		value: 'MACDIndicator',
		attributes: ['window_slow', 'window_fast', 'window_sign'],
		show_ohlc_data_component: true,
		output_data: ['macd_line', 'signal_line', 'macd_histogram'],
	},
	ADXIndicator: {
		value: 'ADXIndicator',
		attributes: ['window'],
		output_data: ['adx', 'adx_pos', 'adx_neg'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	AroonIndicator: {
		value: 'AroonIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
		output_data: ['aroon_up', 'aroon_down', 'aroon_indicator'],
	},
	CCIIndicator: {
		value: 'CCIIndicator',
		attributes: ['window', 'constant'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	DPOIndicator: {
		value: 'DPOIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},
	EMAIndicator: {
		value: 'EMAIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},
	IchimokuIndicator: {
		value: 'IchimokuIndicator',
		attributes: ['window1', 'window2', 'window3'],
		output_data: [
			'ichimoku_conversion_line',
			'ichimoku_base_line',
			'ichimoku_a',
			'ichimoku_b',
		],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	KSTIndicator: {
		value: 'KSTIndicator',
		attributes: [
			'roc1',
			'roc2',
			'roc3',
			'roc4',
			'window1',
			'window2',
			'window3',
			'window4',
			'nsig',
		],
		show_ohlc_data_component: true,
		output_data: ['kst', 'kst_sig', 'kst_diff'],
	},
	MassIndex: {
		value: 'MassIndex',
		attributes: ['window_slow', 'window_fast'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	PSARIndicator: {
		value: 'PSARIndicator',
		attributes: ['step', 'max_step'],
		output_data: [
			'psar',
			'psar_up',
			'psar_down',
			'psar_up_indicator',
			'psar_down_indicator',
		],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	TRIXIndicator: {
		value: 'TRIXIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},
	VortexIndicator: {
		value: 'VortexIndicator',
		attributes: ['window'],
		output_data: [
			'vortex_indicator_pos',
			'vortex_indicator_neg',
			'vortex_indicator_diff',
		],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},

	// Volatility indicators
	AverageTrueRange: {
		value: 'AverageTrueRange',
		attributes: ['window'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	BollingerBands: {
		value: 'BollingerBands',
		attributes: ['window', 'window_dev'],
		show_ohlc_data_component: true,
		output_data: [
			'bollinger_mavg',
			'bollinger_hband',
			'bollinger_lband',
			'bollinger_wband',
			'bollinger_pband',
			'bollinger_hband_indicator',
			'bollinger_lband_indicator',
		],
	},
	DonchianChannel: {
		value: 'DonchianChannel',
		attributes: ['window', 'offset'],
		output_data: [
			'donchian_channel_hband',
			'donchian_channel_lband',
			'donchian_channel_mband',
			'donchian_channel_wband',
			'donchian_channel_pband',
		],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	KeltnerChannel: {
		value: 'KeltnerChannel',
		attributes: ['window'],
		output_data: [
			'keltner_channel_mband',
			'keltner_channel_hband',
			'keltner_channel_lband',
			'keltner_channel_wband',
			'keltner_channel_pband',
			'keltner_channel_hband_indicator',
			'keltner_channel_lband_indicator',
		],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	UlcerIndex: {
		value: 'UlcerIndex',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},

	// Momentum indicators
	AwesomeOscillatorIndicator: {
		value: 'AwesomeOscillatorIndicator',
		attributes: ['window1', 'window2'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	KAMAIndicator: {
		value: 'KAMAIndicator',
		attributes: ['window', 'pow1', 'pow2'],
		show_ohlc_data_component: true,
	},
	PercentagePriceOscillator: {
		value: 'PercentagePriceOscillator',
		attributes: ['window_slow', 'window_fast', 'window_signal'],
		show_ohlc_data_component: true,
		output_data: ['ppo', 'ppo_signal', 'ppo_hist'],
	},
	PercentageVolumeOscillator: {
		value: 'PercentageVolumeOscillator',
		attributes: ['window_slow', 'window_fast', 'window_sign'],
		show_ohlc_data_component: true,
		output_data: ['pvo', 'pvo_signal', 'pvo_hist'],
	},
	ROCIndicator: {
		value: 'ROCIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},
	RSIIndicator: {
		value: 'RSIIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
	},
	StochasticOscillator: {
		value: 'StochasticOscillator',
		attributes: ['window', 'smooth_window'],
		output_data: ['stoch', 'stoch_signal'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	StochRSIIndicator: {
		value: 'StochRSIIndicator',
		attributes: ['window', 'smooth1', 'smooth2'],
		show_ohlc_data_component: true,
		output_data: ['stochrsi', 'stochrsi_k', 'stochrsi_d'],
	},
	TSIIndicator: {
		value: 'TSIIndicator',
		attributes: ['window_slow', 'window_fast'],
		show_ohlc_data_component: true,
	},
	UltimateOscillator: {
		value: 'UltimateOscillator',
		attributes: [
			'window1',
			'window2',
			'window3',
			'weight1',
			'weight2',
			'weight3',
		],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	WilliamsRIndicator: {
		value: 'WilliamsRIndicator',
		attributes: ['lbp'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},

	// Volume indicator
	AccDistIndexIndicator: {
		value: 'AccDistIndexIndicator',
		attributes: [],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	ChaikinMoneyFlowIndicator: {
		value: 'ChaikinMoneyFlowIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	EaseOfMovementIndicator: {
		value: 'EaseOfMovementIndicator',
		attributes: ['window'],
		output_data: ['ease_of_movement', 'sma_ease_of_movement'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	ForceIndexIndicator: {
		value: 'ForceIndexIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	MFIIndicator: {
		value: 'MFIIndicator',
		attributes: ['window'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	NegativeVolumeIndexIndicator: {
		value: 'NegativeVolumeIndexIndicator',
		attributes: [],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	OnBalanceVolumeIndicator: {
		value: 'OnBalanceVolumeIndicator',
		attributes: [],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	VolumePriceTrendIndicator: {
		value: 'VolumePriceTrendIndicator',
		attributes: [],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},
	VolumeWeightedAveragePrice: {
		value: 'VolumeWeightedAveragePrice',
		attributes: ['window'],
		show_ohlc_data_component: true,
		hide_ohlc_data_column: true,
	},

	// Others indicator
	CumulativeReturnIndicator: {
		value: 'CumulativeReturnIndicator',
		attributes: [],
		show_ohlc_data_component: true,
	},
	DailyLogReturnIndicator: {
		value: 'DailyLogReturnIndicator',
		attributes: [],
		show_ohlc_data_component: true,
	},
	DailyReturnIndicator: {
		value: 'DailyReturnIndicator',
		attributes: [],
		show_ohlc_data_component: true,
	},
}

export const TAComparators = {
	crossed_up_and_still_up: {
		value: 'crossed_up_and_still_up',
		name: 'Crossing Up And Still Up',
		attributes: ['window'],
	},
	crossed_down_and_still_down: {
		value: 'crossed_down_and_still_down',
		name: 'Crossing Down And Still Down',
		attributes: ['window'],
	},
	greater_than: {
		value: 'greater_than',
		name: 'Greater Than',
		attributes: [],
	},
	greater_than_or_equal_to: {
		value: 'greater_than_or_equal_to',
		name: 'Greater Than or Equal To',
		attributes: [],
	},
	less_than: {
		value: 'less_than',
		name: 'Less Than',
		attributes: [],
	},
	less_than_or_equal_to: {
		value: 'less_than_or_equal_to',
		name: 'Less Than or Equal To',
		attributes: [],
	},
	equal_to: {
		value: 'equal_to',
		name: 'Equal To',
		attributes: [],
	},
	crossing: {
		value: 'crossing',
		name: 'Crossing',
		attributes: [],
	},
	crossing_up: {
		value: 'crossing_up',
		name: 'Crossing Up',
		attributes: [],
	},
	crossing_down: {
		value: 'crossing_down',
		name: 'Crossing Down',
		attributes: [],
	},
	moving_up: {
		value: 'moving_up',
		name: 'Moving Up',
		attributes: ['window'],
	},
	moving_down: {
		value: 'moving_down',
		name: 'Moving Down',
		attributes: ['window'],
	},
	moving_up_percentage: {
		value: 'moving_up_percentage',
		name: 'Moving Up Percentage',
		attributes: ['window'],
	},
	moving_down_percentage: {
		value: 'moving_down_percentage',
		name: 'Moving Down Percentage',
		attributes: ['window'],
	},
	entering_channel: {
		value: 'entering_channel',
		name: 'Entering Channel',
		attributes: [],
	},
	exiting_channel: {
		value: 'exiting_channel',
		name: 'Exiting Channel',
		attributes: [],
	},
	inside_channel: {
		value: 'inside_channel',
		name: 'Inside Channel',
		attributes: [],
	},
	outside_channel: {
		value: 'outside_channel',
		name: 'Outside Channel',
		attributes: [],
	},
}

export const NewOrderHeaders = [
	'order_change_time',
	'type',
	'instrument',
	'order_type',
	'quantity',
	'best_price',
	'price',
	'status',
	'pair',
	'order_type',
]

export const NewOrderCellPadding = [22, 6, 18, 8, 8, 8, 8, 8, 12, 8]

export const chartData = [
	{
		date: new Date('2020-01-01 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 411,
	},
	{
		date: new Date('2020-01-02 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 584,
	},
	{
		date: new Date('2020-01-03 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5811,
	},
	{
		date: new Date('2020-01-04 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5811,
	},
	{
		date: new Date('2020-01-05 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 554,
	},
	{
		date: new Date('2020-01-06 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5411,
	},
	{
		date: new Date('2020-01-07 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5311,
	},
	{
		date: new Date('2020-01-08 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 524,
	},
	{
		date: new Date('2020-01-09 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5141,
	},
	{
		date: new Date('2020-01-10 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5081,
	},
	{
		date: new Date('2020-01-11 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 494,
	},
	{
		date: new Date('2020-01-12 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 481,
	},
	{
		date: new Date('2020-01-13 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 41,
	},
	{
		date: new Date('2020-01-14 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-01-15 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5941,
	},
	{
		date: new Date('2020-01-16 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-01-17 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-01-18 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5941,
	},
	{
		date: new Date('2020-01-19 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-01-20 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-01-21 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5481,
	},
	{
		date: new Date('2020-01-22 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-01-23 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-01-24 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5411,
	},
	{
		date: new Date('2020-01-25 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5911,
	},
	{
		date: new Date('2020-01-26 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-01-27 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-01-28 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-01-29 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5411,
	},
	{
		date: new Date('2020-01-30 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 3.4,
		volume: 5948,
	},
	{
		date: new Date('2020-01-31 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5941,
	},
	{
		date: new Date('2020-02-01 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5981,
	},

	{
		date: new Date('2020-02-02 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-03 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-02-04 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 5981,
	},
	{
		date: new Date('2020-02-05 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-06 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 5481,
	},
	{
		date: new Date('2020-02-07 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-08 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-09 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-10 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-11 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-12 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-13 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-14 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-15 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-16 00:00:00'),
		open: 33,
		high: 33.5,
		low: 32.98,
		close: 33.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-17 00:00:00'),
		open: 33.4,
		high: 35,
		low: 31,
		close: 32.4,
		volume: 594,
	},
	{
		date: new Date('2020-02-18 00:00:00'),
		open: 32.4,
		high: 27,
		low: 28,
		close: 33.4,
		volume: 594,
	},
]
