import * as actionTypes from '../actions'

const InstrumentReducer = (state = {}, action) => {
	let newState
	switch (action.type) {
		case actionTypes.GET_SUBSCRIBED_INSTRUMENTS_SUCCESS:
			newState = { ...state, subscribed: action.data }
			break
		default:
			return state
	}
	return newState
}

export default InstrumentReducer
