import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	content: {
		// backgroundColor: '#F6F6F6',
		paddingBottom: '30px',
		minHeight: 500,
	},
}))

const Layout = ({ children }) => {
	const classes = useStyles()

	return (
		<>
			{/* <HeaderTemplate /> */}
			<Box className={classes.content}>{children}</Box>
			{/* <FooterTemplate /> */}
		</>
	)
}
export default Layout
