import api from '.'

export const fetchTokenStatusApi = (requestToken = null) => {
	let url = ''
	if (requestToken !== null)
		url = `account/token?request_token=${requestToken}`
	else url = `account/token`
	let config = {
		method: 'GET',
		url: url,
	}

	return api(config).then((resp) => {
		return resp
	})
}
