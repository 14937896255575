import React from 'react'
import { Button, Box } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

export default function AlertDialog({
	handleClose,
	onConfirmedHandler,
	open,
	isDeleting,
	error,
	message,
}) {
	const updatedMessage = isDeleting
		? 'Are you sure <br/> You want to delete this?'
		: message
		? message
		: 'There are some changes <br/> Do you want to save them ?'

	const confirmDialogHandler = (confirmed) => {
		if (confirmed) {
			onConfirmedHandler()
			handleClose()
		} else {
			handleClose()
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Box display="flex" justifyContent="flex-end">
				<IconButton
					onClick={() => {
						handleClose()
					}}
					aria-label="delete"
				>
					<ClearIcon fontSize="medium" />
				</IconButton>
			</Box>
			<DialogContent>
				<DialogContentText
					style={{ textAlign: 'center' }}
					id="alert-dialog-description"
				>
					<div
						dangerouslySetInnerHTML={{
							__html: updatedMessage,
						}}
					></div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{error ? (
					<Button
						onClick={() => {
							handleClose()
						}}
						color="primary"
					>
						Okay
					</Button>
				) : (
					<>
						<Button
							onClick={() => {
								confirmDialogHandler(false)
							}}
							color="primary"
						>
							No
						</Button>
						<Button
							onClick={() => {
								confirmDialogHandler(true)
							}}
							color="primary"
							autoFocus
						>
							Yes
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	)
}
