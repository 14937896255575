import { applyMiddleware, createStore } from 'redux'
import rootReducer from './reducers'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import history from '../routes/History'

const sagaMiddleware = createSagaMiddleware()

const logger = (store) => (next) => (action) => {
	console.group(action.type)
	console.info('dispatching', action)
	let result = next(action)
	console.log('next state', store.getState())
	console.groupEnd()
	return result
}

const middleWareEnhancer = applyMiddleware(
	logger,
	sagaMiddleware,
	routerMiddleware(history)
)

const composedEnhancers = composeWithDevTools(middleWareEnhancer)

const store = createStore(rootReducer, composedEnhancers)

sagaMiddleware.run(sagas)

if (process.env.NODE_ENV !== 'production' && module.hot) {
	module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
}

export default store
