import * as actionTypes from '../actions'

const OrderReducer = (state = {}, action) => {
	let newState
	switch (action.type) {
		case actionTypes.GET_ORDERS:
			if (
				action.data.data.status.toLowerCase() === 'open' ||
				action.data.data.status.toLowerCase() === 'placed to zerodha' ||
				action.data.data.status.toLowerCase() === 'pending'
			) {
				newState = { ...state, open_order_update: action.data }
			} else {
				newState = { ...state, executed_order_update: action.data }
			}
			break
		case actionTypes.SHOW_ORDER_SNACKBAR:
			newState = { ...state, snackbarText: action.data }
			break
		case actionTypes.HIDE_ORDER_SNACKBAR:
			newState = { ...state, snackbarText: null }
			break
		default:
			return state
	}
	return newState
}

export default OrderReducer
