import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

export default function ScrollToTop(props) {
	const { pathname } = useLocation()

	const history = useHistory()

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GA_KEY)
		let reactGaListner = history.listen((location, action) => {
			ReactGA.pageview(location.pathname + location.search)
		})
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}
