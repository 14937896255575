import React, { useState, useEffect } from 'react'
import { makeStyles, Box, Button } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useHistory, useLocation } from 'react-router-dom'
import AlertDialog from '../common/AlertDialog'
import { editingPagesRoutes } from '../../store/constants'

const useStyles = makeStyles(() => ({
	activeTab: {
		backgroundColor: '#E8E8E8',
	},
	item: {
		textTransform: 'none',
	},
}))

export default function SidebarListItem({
	data,
	collapseItemState,
	setCollapseItemState,
}) {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()

	const [permalinkState, setPermalinkState] = useState('')
	const [alertState, setAlertState] = useState(false)
	const [isEditingPage, setIsEditingPage] = useState(
		editingPagesRoutes.some((elem) => {
			return history.location.pathname.indexOf(elem) > 0
		})
	)

	useEffect(() => {
		setIsEditingPage(
			editingPagesRoutes.some((elem) => {
				return history.location.pathname.indexOf(elem) > 0
			})
		)
	}, [location.pathname])

	const changeCollapseItemState = () => {
		setCollapseItemState((prev) => ({
			...collapseItemState,
			[data.tab_name]: !prev[data.tab_name],
		}))
	}

	const redirectToLink = (newTab, link) => {
		if (newTab) {
			return window.open(`/${link || permalinkState}`, '_blank')
		}
		return history.push(`/${link || permalinkState}`)
	}
	return (
		<Box display="flex">
			<ListItem
				className={`${classes.item} ${
					location.pathname.includes(data.permalink)
						? classes.activeTab
						: ''
				}`}
				component={Button}
				activeClassName={classes.activeTab}
				onClick={(e) => {
					if (data.permalink) {
						if (isEditingPage) {
							setAlertState(true)
							setPermalinkState(data.permalink)
							return
						}
						if (e.ctrlKey || e.metaKey) {
							redirectToLink(true, data.permalink)
						} else {
							redirectToLink(false, data.permalink)
						}
						return
					}
					changeCollapseItemState()
				}}
				button
			>
				<ListItemText primary={data.tab_name} />
				{data.children.length > 0 &&
					(collapseItemState[data.tab_name] ? (
						<ExpandLess />
					) : (
						<ExpandMore />
					))}
			</ListItem>
			<AlertDialog
				open={!!alertState}
				onConfirmedHandler={redirectToLink}
				handleClose={() => setAlertState(false)}
				customAlertMsg="Do You Want to Leave this Page ?"
			/>
		</Box>
	)
}
