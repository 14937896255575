import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Box, Snackbar, IconButton } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Sidebar from '../components/Sidebar/Sidebar'
import api from '../store/network/apis'
import { useAuth } from '../contexts/Auth'
import { useWampy } from '../contexts/Wampy'
import { hide_sidebar_url } from '../store/constants'
import * as actionTypes from '../store/actions'

import Notifications from '../components/Notifications/Notifications'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: 'flex',
	},
	content: {
		width: '100%',
		display: 'flex',
		justifyContent: 'right',
		alignItems: 'start',
	},
	openedLiveChartIcon: {
		right: theme.admin.drawerWidth - 50,
		top: '3rem',
	},
	ArrowForwardIosIcon: {
		left: '-5px',
		top: '1rem',
	},
	ArrowBackIosIcon: {
		left: theme.admin.drawerWidth - 75,
		top: '1rem',
	},
	closedLiveChartIcon: {
		right: '0.3rem',
		top: '3rem',
	},
	liveChartIcon: {
		zIndex: 10000,
		position: 'fixed',
		color: 'blue',
	},
}))

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const AdminLayout = ({
	children,
	utility,
	hideSnackbar,
	showErrorSnackbar,
}) => {
	const classes = useStyles()
	const auth = useAuth()
	const wampy = useWampy()
	const url_pathname = window.location.pathname
	const [hideSidebar, setHideSidebar] = useState(
		hide_sidebar_url.some((rx) => url_pathname.match(rx))
	)
	useEffect(() => {
		if (utility.snackbarText) hideSnackbar()
		api.interceptors.response.use(
			(response) => {
				if (response.response && response.response.status === 401) {
					auth.logout()
				}
				return response
			},
			(error) => {
				// if (error.response.status === 401) {
				// 	showErrorSnackbar(error.response.data.message);
				// } else {
				// 	showErrorSnackbar('An Error Occured in Recent Api Call');
				// }
				// showErrorSnackbar('An Error Occured in Recent Api Call');
				if (error.response === undefined) {
					showErrorSnackbar(
						'Some error occurred. Please try again later.'
					)
				} else {
					showErrorSnackbar(error.response.data.error)
				}
				throw error
			}
		)
	}, [])

	useEffect(() => {
		if (wampy.connectionError) {
			showErrorSnackbar(wampy.errorMessage)
		}
	}, [wampy.connectionError])

	return (
		<>
			{/* <AdminHeaderTemplate /> */}
			<div className={classes.root}>
				{hideSidebar ? null : <Sidebar />}

				<Box p={2} className={classes.content}>
					<IconButton
						aria-label="delete"
						style={{
							zIndex: 10000,
							position: 'fixed',
							color: 'blue',
						}}
						className={
							hideSidebar
								? classes.ArrowForwardIosIcon
								: classes.ArrowBackIosIcon
						}
						onClick={() => {
							setHideSidebar(!hideSidebar)
						}}
					>
						{hideSidebar ? (
							<ArrowForwardIosIcon fontSize="medium" />
						) : (
							<ArrowBackIosIcon fontSize="medium" />
						)}
					</IconButton>

					{children}
					<Notifications />
				</Box>

				{utility.snackbarText && (
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						open={!!utility.snackbarText}
						autoHideDuration={4000}
						onClose={() => hideSnackbar()}
					>
						<Alert
							onClose={hideSnackbar}
							severity="info"
							sx={{ width: '100%' }}
						>
							{utility.snackbarText}
						</Alert>
					</Snackbar>
				)}
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return { utility: state.utility }
}

const mapDispatchToProps = (dispatch) => ({
	hideSnackbar: () => dispatch({ type: actionTypes.HIDE_SNACKBAR }),
	showErrorSnackbar: (text) =>
		dispatch({ type: actionTypes.SHOW_SNACKBAR, data: text }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
