import React, { useState } from 'react'
import { makeStyles, Drawer, Typography, Button, Box } from '@material-ui/core'
import SidebarList from './SidebarList'
import { manageConfigApi } from '../../store/network/apis/pairApis'
import AlertDialog from '../common/AlertDialog'
import { useAuth } from '../../contexts/Auth'
import { capitalize } from '../../store/helpers'

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: theme.admin.drawerWidth - 50,
		flexShrink: 0,
	},
	drawerPaper: {
		width: theme.admin.drawerWidth - 50,
	},
	drawerPaper2: {
		width: theme.admin.drawerWidth,
		zIndex: 0,
		borderRight: 'none',
	},
	manageConfigButton: {
		margin: theme.spacing(1, 1, 1, 1),
	},
}))

const SidebarAdmin = ({ title }) => {
	const classes = useStyles()
	const auth = useAuth()
	const [manageConfigState, setManageConfigState] = useState(true)
	const [alertDialogState, setAlertDialogState] = useState(false)

	const manageConfigHandler = () => {
		manageConfigApi(null, 'kill_all').then((resp) => {
			if (resp.status === 200) setManageConfigState(false)
		})
	}

	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor="left"
		>
			<Box
				style={{
					boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 26%)',
					textAlign: 'center',
					padding: '0.2rem',
				}}
			>
				<Button
					onClick={() => setAlertDialogState(true)}
					className={classes.manageConfigButton}
					variant="outlined"
				>
					{manageConfigState ? 'Stop All' : 'Run All'}
				</Button>

				{title && (
					<Typography
						className={classes.title}
						align="center"
						variant="h6"
					>
						{title}
					</Typography>
				)}
				{auth.user && (
					<Typography className={classes.title} align="center">
						{capitalize(auth.user.name)}
					</Typography>
				)}
			</Box>
			<SidebarList />
			{alertDialogState && (
				<AlertDialog
					open={alertDialogState}
					onConfirmedHandler={manageConfigHandler}
					handleClose={() => setAlertDialogState(false)}
					message={`Are You Sure? <br/> You want to ${
						manageConfigState ? 'Stop All' : 'Run All'
					}`}
				/>
			)}
		</Drawer>
	)
}

export default SidebarAdmin
