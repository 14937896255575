import * as actionTypes from '../actions'

const UtilityReducer = (state = { test: 'test' }, action) => {
	let newState
	switch (action.type) {
		case actionTypes.SHOW_SNACKBAR:
			newState = { ...state, snackbarText: action.data }
			break
		case actionTypes.HIDE_SNACKBAR:
			newState = { ...state, snackbarText: null }
			break
		case actionTypes.ADD_CURRENT_TABLE_ROW:
			newState = {
				...state,
				currentTableRow: action.data,
				highlightedTableRow: action.data,
			}
			break
		case actionTypes.REMOVE_HIGHLIGHTED_TABLE_ROW:
			newState = { ...state, highlightedTableRow: null }
			break
		case actionTypes.ADD_UPDATED_TABLE_ROW:
			newState = { ...state, updatedTableRow: true }
			break
		case actionTypes.REMOVE_UPDATED_TABLE_ROW:
			newState = {
				...state,
				updatedTableRow: null,
				currentTableRow: null,
			}
			break
		default:
			return state
	}
	return newState
}

export default UtilityReducer
