import { all, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../actions'
import { getBacktestDataSaga } from './backtestSaga'

import { getSubscribedInstrumentSaga } from './instrumentSaga'
import { getPairDataSaga } from './pairSaga'

const mySaga = function*() {
	yield all([
		takeLatest(
			actionTypes.GET_SUBSCRIBED_INSTRUMENTS,
			getSubscribedInstrumentSaga
		),
		takeLatest(actionTypes.GET_BACKTEST_DATA, getBacktestDataSaga),
		takeLatest(actionTypes.GET_PAIR_DATA, getPairDataSaga),
	])
}

export default mySaga
