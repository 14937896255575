import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { useAuth } from '../contexts/Auth'
import AdminLayout from '../hoc/AdminLayout'
import MasterBoundary from '../components/common/ErrorBoundaries/MasterBoundary'
import ScrollToTop from '../components/common/ScrollToTop'
import Layout from '../hoc/Layout'
import history from './History'
// ========= Components =========
import * as LazyComponent from './LazyLoaded'

const Routes = () => {
	const auth = useAuth()

	return (
		<Suspense fallback="loading...">
			<ConnectedRouter history={history}>
				<MasterBoundary>
					<ScrollToTop />

					<Switch>
						{auth.adminUser.isLoggedIn && !auth.user.isLoggedIn ? (
							<Switch>
								<Route
									path="/admin/zerodha-login"
									component={LazyComponent.ZerodhaLoginPage}
								/>
								<Route
									path="/"
									component={LazyComponent.AdminUserListPage}
								/>
							</Switch>
						) : (
							<Route path="/admin">
								{auth.user.isLoggedIn ? (
									<AdminLayout>
										<Switch>
											<Route
												path="/admin/trigger-logs/:type/:id?"
												component={
													LazyComponent.TriggerLogsPage
												}
											/>
											<Route
												path="/admin/audit-logs"
												component={
													LazyComponent.AuditLogsPage
												}
											/>
											<Route
												path="/admin/backorder"
												component={
													LazyComponent.BackorderPage
												}
											/>
											<Route
												path="/admin/market-depth"
												component={
													LazyComponent.MarketDepthPage
												}
											/>
											<Route
												path="/admin/logs/:type/:id?"
												component={
													LazyComponent.LogsPage
												}
											/>
											<Route
												path="/admin/pairs"
												component={
													LazyComponent.PairsPage
												}
											/>
											<Route
												path="/admin/price-adjusters"
												component={
													LazyComponent.PriceAdjusterPage
												}
											/>
											<Route
												path="/admin/groups"
												component={
													LazyComponent.GroupsPage
												}
											/>
											<Route
												path="/admin/legs"
												component={
													LazyComponent.LegsPage
												}
											/>
											<Route
												path="/admin/legs"
												component={
													LazyComponent.LegsPage
												}
											/>
											<Route
												path="/admin/portfolio/holdings"
												component={
													LazyComponent.HoldingsPage
												}
											/>
											<Route
												path="/admin/portfolio/positions"
												component={
													LazyComponent.PostionsPage
												}
											/>
											<Route
												path="/admin/subscription"
												component={
													LazyComponent.SubscriptionPage
												}
											/>
											<Route
												path="/admin/instruments"
												component={
													LazyComponent.InstrumentsPage
												}
											/>
											<Route
												path="/admin/chart/:instrument_id"
												component={
													LazyComponent.MarketDepthChart
												}
											/>
											<Route
												path="/admin/orders"
												component={
													LazyComponent.OrdersPage
												}
											/>
											<Route
												path="/admin/zerodha-login"
												component={
													LazyComponent.ZerodhaLoginPage
												}
											/>
											<Route
												path="/admin/instrument-live-data/:instrument_id/:name/:tradingsymbol/:exchange"
												component={
													LazyComponent.IntrumentsLiveData
												}
											/>
											<Route
												path="/admin/technical-indicators"
												component={
													LazyComponent.TechnicalIndicator
												}
											/>
											<Route
												path="/admin/plot-chart"
												component={
													LazyComponent.LegsOHLCChart
												}
											/>
										</Switch>
									</AdminLayout>
								) : (
									<Redirect to="/auth" />
								)}
							</Route>
						)}
						<Route path="/">
							<Layout>
								<Switch>
									<LazyComponent.AuthPage path="/auth" />
									{/* <LazyComponent.Home path="/" exact /> */}
									<Redirect to="/auth" />
								</Switch>
							</Layout>
						</Route>
					</Switch>
				</MasterBoundary>
			</ConnectedRouter>
		</Suspense>
	)
}

export default Routes
