import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import SidebarListItem from './SidebarListItem'
import { useAuth } from '../../contexts/Auth'
import { sidebarItemsData } from '../../store/constants'
import AlertDialog from '../common/AlertDialog'

const useStyles = makeStyles(() => ({
	root: {},
	sideBarScroll: {
		overflow: 'scroll',
	},
}))

const SidebarList = () => {
	const classes = useStyles()
	const auth = useAuth()
	const history = useHistory()
	const [collapseItemState, setCollapseItemState] = React.useState({})
	const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false)

	const handler = (children) => {
		children = children || []
		return children.map((subOption) => {
			return subOption.children && subOption.children.length === 0 ? (
				<SidebarListItem
					collapseItemState={collapseItemState}
					setCollapseItemState={(updatedState) =>
						setCollapseItemState(updatedState)
					}
					data={subOption}
				/>
			) : (
				<>
					<SidebarListItem
						collapseItemState={collapseItemState}
						setCollapseItemState={(updatedState) =>
							setCollapseItemState(updatedState)
						}
						data={subOption}
					/>
					<Box
						width="100%"
						pl={5}
						component={Collapse}
						in={collapseItemState[subOption.tab_name]}
						timeout="auto"
						unmountOnExit
					>
						{handler(subOption.children)}
					</Box>
				</>
			)
		})
	}
	const logoutHandler = () => {
		auth.logout()
		history.push('/auth')
	}

	const sidebarList = handler(sidebarItemsData) || []

	return (
		<>
			<List className={classes.sideBarScroll}>
				{sidebarList}
				<ListItem onClick={() => setLogoutDialogOpen(true)} button>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>
			{logoutDialogOpen && (
				<AlertDialog
					open={logoutDialogOpen}
					handleClose={() => setLogoutDialogOpen(false)}
					onConfirmedHandler={logoutHandler}
					message="Are you sure you want to logout ?"
				/>
			)}
		</>
	)
}
export default SidebarList
