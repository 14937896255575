import api from './'
import { filterFormatter } from '../../helpers'

export const createBacktestJobApi = (data) => {
	let config = {
		method: 'POST',
		url: `/backtest`,
		data,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const fetchBackorderJobsApi = (filter) => {
	let config = {
		method: 'GET',
		url: `/backtest?filter=${filterFormatter(filter)}`,
	}

	return api(config).then((resp) => {
		return resp
	})
}

export const cancelBackorderJobsApi = (id) => {
	let config = {
		method: 'PUT',
		url: `/backtest/cancel`,
		data: { backtesting_id: id },
	}

	return api(config).then((resp) => {
		return resp
	})
}
