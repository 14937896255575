import api from '.'
import { filterFormatter } from '../../helpers'
import axios from 'axios'

export const fetchUsersApi = (filter, adminUser) => {
	const url = `${
		process.env.REACT_APP_BASE_URL
	}/account/users?filter=${filterFormatter(filter)}`

	return axios
		.get(url, {
			headers: { Authorization: adminUser.authToken },
		})
		.then((resp) => {
			return resp
		})
		.catch((err) => {
			return err
		})
}

export const adminLogin = (payload, adminUser) => {
	const url = `${process.env.REACT_APP_BASE_URL}/user/admin-login`

	return axios
		.post(url, payload, {
			headers: { Authorization: adminUser.authToken },
		})
		.then((resp) => {
			return resp
		})
		.catch((err) => {
			return err
		})
}

export const fetchAdminTokenStatusApi = (requestToken, adminUser) => {
	let url = `${process.env.REACT_APP_BASE_URL}/account/token`
	if (requestToken !== undefined)
		url = `${process.env.REACT_APP_BASE_URL}/account/token?request_token=${requestToken}`

	return axios
		.get(url, {
			headers: { Authorization: adminUser.authToken },
		})
		.then((resp) => {
			return resp
		})
		.catch((err) => {
			return err
		})
}
