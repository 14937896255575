export const InstrumentFilterFormatter = (filter) => {
	if (typeof filter == 'object') return `query=${JSON.stringify(filter)}`
	return `filter=${filter}`
}

export const filterFormatter = (filter) => {
	if (typeof filter == 'object') filter = JSON.stringify(filter)
	return filter
}

export const capitalize = function(string) {
	// Split the string by space and underscore
	if (!string) {
		return string
	}
	let result = ''
	try {
		let words = string.split(/[ _]+/)
		words.map((word) => {
			result += word.charAt(0).toUpperCase() + word.slice(1) + ' '
		})
	} catch {
		return typeof string === 'string' ? string.toUpperCase() : string
	}
	return typeof result === 'string' ? result.toUpperCase() : result
}

export const isSmaller = (value1, value2) => {
	if (value1 === undefined || value2 === undefined) {
		return false
	}

	return value1 < value2
}

export const isGreater = (value1, value2) => {
	if (value1 === undefined || value2 === undefined) {
		return false
	}

	return value1 > value2
}
